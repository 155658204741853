import React from 'react';

import { Loader } from 'components/Loader';
import { getStressTask, getStressTaskResponse } from 'services/backendRequests';

import StressForm from './StressForm';
import styles from './StressPage.module.scss';

const StressPage: React.FC = () => {
  const [ task, setTask ] = React.useState<getStressTaskResponse | null>(null);
  const recieveNewTask = () => {
    getStressTask().then(setTask);
  };

  React.useEffect(recieveNewTask, []);

  if (task) {
    return (
      <div className={styles.StressPage}>
        <StressForm
          task={task}
          recieveNewTask={recieveNewTask}
        />
      </div>
    );
  }

  return <Loader />;
};

export default StressPage;
