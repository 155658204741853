import React from 'react';

import { Button } from 'components/Button';
import {
  getStressTaskResponse,
} from 'services/backendRequests';

import styles from './StressForm.module.scss';

type StressFormProps = {
  task: getStressTaskResponse;
  recieveNewTask: VoidFunction;
};

const StressForm: React.FC<StressFormProps> = ({
  task,
  recieveNewTask
}) => {
  const handleNewTask: React.MouseEventHandler<HTMLButtonElement> = () => {
    recieveNewTask();
  };

  const handleOneTaskDone: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    (event.target as HTMLButtonElement).setAttribute('done', 'yes');
    // if ((event.target as HTMLButtonElement).nextElementSibling) {
    //   ((event.target as HTMLButtonElement).nextElementSibling as HTMLButtonElement).setAttribute('done', 'yes');
    // } else {
    //   ((event.target as HTMLButtonElement).previousElementSibling as HTMLButtonElement).setAttribute('done', 'yes');
    // }
  };

  return (
    <div className={styles.StressForm}>
      {task.map(({ options }, index) =>
        <div key={`${options[0].word}-${index}`} className={styles.StressForm__oneTask}>
          <button className={options[0].correct ? styles.StressForm__word_correct : styles.StressForm__word_incorrect}
            onClick={handleOneTaskDone}>{options[0].word}</button>
          <button className={options[1].correct ? styles.StressForm__word_correct : styles.StressForm__word_incorrect}
            onClick={handleOneTaskDone}>{options[1].word}</button>
        </div>
      )}
      <div className={styles.StressForm__buttonsBlock}>
        <Button
          onClick={handleNewTask}
          className={styles.StressForm__newWordsButton}
        >
          Новые слова
        </Button>
      </div>
    </div>
  );
};

export default StressForm;
