import React from 'react';

import { Button } from 'components/Button';
import {
  getGenitiveTaskResponse,
} from 'services/backendRequests';

import styles from './GenitiveForm.module.scss';

type GenitiveFormProps = {
  task: getGenitiveTaskResponse;
  recieveNewTask: VoidFunction;
};

const GenitiveForm: React.FC<GenitiveFormProps> = ({
  task,
  recieveNewTask
}) => {
  const handleNewTask: React.MouseEventHandler<HTMLButtonElement> = () => {
    recieveNewTask();
  };

  const handleOneTaskDone: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    (event.target as HTMLButtonElement).setAttribute('done', 'yes');
    // if ((event.target as HTMLButtonElement).nextElementSibling) {
    //   ((event.target as HTMLButtonElement).nextElementSibling as HTMLButtonElement).setAttribute('done', 'yes');
    // } else {
    //   ((event.target as HTMLButtonElement).previousElementSibling as HTMLButtonElement).setAttribute('done', 'yes');
    // }
  };

  return (
    <div className={styles.GenitiveForm}>
      {task.map(({ options, initial }, index) =>
        <div key={`${initial}-${index}`} className={styles.GenitiveForm__oneTask}>
          <div className={styles.GenitiveForm__initial}>{initial}</div>
          <button className={options[0].correct ? styles.GenitiveForm__word_correct : styles.GenitiveForm__word_incorrect}
            onClick={handleOneTaskDone}>{options[0].word}</button>
          <button className={options[1].correct ? styles.GenitiveForm__word_correct : styles.GenitiveForm__word_incorrect}
            onClick={handleOneTaskDone}>{options[1].word}</button>
        </div>
      )}
      <div className={styles.GenitiveForm__buttonsBlock}>
        <Button
          onClick={handleNewTask}
          className={styles.GenitiveForm__newWordsButton}
        >
          Новые слова
        </Button>
      </div>
    </div>
  );
};

export default GenitiveForm;
