import React from 'react';

import { Loader } from 'components/Loader';
import { getGenitiveTask, getGenitiveTaskResponse } from 'services/backendRequests';

import GenitiveForm from './GenitiveForm';
import styles from './GenitivePage.module.scss';

const GenitivePage: React.FC = () => {
  const [ task, setTask ] = React.useState<getGenitiveTaskResponse | null>(null);
  const recieveNewTask = () => {
    getGenitiveTask().then(setTask);
  };

  React.useEffect(recieveNewTask, []);

  if (task) {
    return (
      <div className={styles.GenitivePage}>
        <GenitiveForm
          task={task}
          recieveNewTask={recieveNewTask}
        />
      </div>
    );
  }

  return <Loader />;
};

export default GenitivePage;
